import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const lightBackgroundClasses = [
  'chooseBackground6',
  'chooseBackground7',
  'chooseBackground8',
  'chooseBackground9',
  'chooseBackground10',
];

const lightBackgroundSolidClasses = [
  'backgroundColorBlack3',
  'backgroundColorBlack1',
  'backgroundColorBlack1',
  'backgroundColorBlack2',
  'backgroundColorBlack3',
];

const darkBackgroundClasses = [
  'chooseBackground1',
  'chooseBackground2',
  'chooseBackground3',
  'chooseBackground4',
  'chooseBackground5',
];

const darkBackgroundSolidClasses = [
  'backgroundColorPink5',
  'backgroundColorGreen5',
  'backgroundColorPink4',
  'backgroundColorGreen4',
  'backgroundColorBlack5',
];

const BackgroundManager = ({ children, pathname }) => {
  const [backgroundClass, setBackgroundClass] = useState('');
  const [solidBackgroundClass, setSolidBackgroundClass] = useState('');

  useEffect(() => {
    const isDarkTheme = ['/contact', '/', '/segmind'].includes(pathname);
    const gradientClassArray = isDarkTheme ? darkBackgroundClasses : lightBackgroundClasses;
    const solidClassArray = isDarkTheme ? darkBackgroundSolidClasses : lightBackgroundSolidClasses;
    
    // Use the same random index for both arrays
    const randomIndex = Math.floor(Math.random() * gradientClassArray.length);
    const selectedGradientClass = gradientClassArray[randomIndex];
    const selectedSolidClass = solidClassArray[randomIndex];

    // Update the gradient background
    const gradientDiv = document.getElementById('customBackgroundColor');
    if (gradientDiv) {
      // Remove all gradient background classes
      gradientDiv.classList.remove(...lightBackgroundClasses, ...darkBackgroundClasses);
      // Add the new gradient background class
      gradientDiv.classList.add(selectedGradientClass);
    }

    // Update the solid background
    const solidDiv = document.getElementById('customBodyBackground');
    if (solidDiv) {
      // Remove all solid background classes
      solidDiv.classList.remove(...lightBackgroundSolidClasses, ...darkBackgroundSolidClasses);
      // Add the new solid background class
      solidDiv.classList.add(selectedSolidClass);
    }

    setBackgroundClass(selectedGradientClass);
    setSolidBackgroundClass(selectedSolidClass);
  }, [pathname]);

  return <div id="customBackgroundColor">{children}</div>;
};

const withRandomBackground = (WrappedComponent) => {
  return function WithRandomBackground(props) {
    const location = useLocation();
    return (
      <BackgroundManager pathname={location.pathname}>
        <WrappedComponent {...props} />
      </BackgroundManager>
    );
  };
};

export default withRandomBackground;