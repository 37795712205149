import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SegmindPixelflow = () => {
    const [images, setImages] = useState({ outputImage1: '', outputImage2: '', outputImage3: '' });
    const [quote, setQuote] = useState('');
    const [prompt, setPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(null);

    const modelInfo = {
        outputImage1: {
            name: 'Ideogram',
            url: 'https://www.segmind.com/models/ideogram-txt-2-img'
        },
        outputImage2: {
            name: 'Stable Diffusion 3.5 Turbo',
            url: 'https://www.segmind.com/models/stable-diffusion-3.5-turbo-txt2img'
        },
        outputImage3: {
            name: 'Recraft V3',
            url: 'https://www.segmind.com/models/recraft-v3'
        }
    };

    const extractQuote = (text) => {
        const matches = text.match(/"([^"]+)"/);
        return matches ? `Generated quote from ChatGPT4o: "${matches[1]}"` : text;
    };

    useEffect(() => {
        let interval;
        if (isLoading) {
            interval = setInterval(() => {
                setCounter(prev => prev + 1);
            }, 1000);
        } else {
            setCounter(0);
        }
        return () => clearInterval(interval);
    }, [isLoading]);

    const generateImages = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) return;

        setIsLoading(true);
        setError(null);
        
        try {
            const response = await axios.post('http://localhost:5555/api/pixelflow', {
                genPrompt: prompt,
                genInstructions: `Create text prompt for a photo flyer which takes a prompt and generates text in a single image along with contextual image. Below the text, there is is a contextual image depicting the meaning of the above quote. Here is an example: 'A flyer with a black text written in a serif typeface on a white background. The text says "Be the change that you wish to see in the world."'`
            });

            if (response.data) {
                setImages({
                    outputImage1: response.data.outputImage1,
                    outputImage2: response.data.outputImage2,
                    outputImage3: response.data.outputImage3
                });
                setQuote(extractQuote(response.data.outputQuote));
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to generate images');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="content">
            <form onSubmit={generateImages} className="mb-5">
                <div className="field">
                    <label className="label ml-3 has-text-weight-bold colorBlack2">
                        Provide a prompt (Model: ChatGPT 4o)
                    </label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Describe the feeling you want to convey in your Meme..."
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="mt-5 is-flex is-align-items-center">
                    <button 
                        type="submit"
                        className={`button is-light ${isLoading ? 'is-loading' : ''}`}
                        disabled={isLoading || !prompt.trim()}
                    >
                        Generate Images
                    </button>
                    {isLoading && (
                        <p className="has-text-weight-bold colorBlack2 ml-4 mb-0">
                            Generating your images... {counter}s
                        </p>
                    )}
                </div>
            </form>

            {error ? (
                <p className="has-text-weight-bold has-text-danger">{error}</p>
            ) : quote && (
                <>
                    <p className="has-text-weight-bold has-text-centered colorBlack2 is-size-4 mt-6 mb-4">{quote}</p>
                    <div className="columns is-multiline">
                        {Object.entries(images).map(([key, url]) => (
                            url && (
                                <div key={key} className="column is-4">
                                    <p className="mt-4 mb-2 has-text-centered">
                                        <a 
                                            href={modelInfo[key].url} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="has-text-weight-bold colorPink2 hover-underline is-size-5"
                                        >
                                            {modelInfo[key].name}
                                        </a>
                                    </p>
                                    <figure className="image is-1by1">
                                        <img 
                                            src={url} 
                                            alt={`Generated by ${modelInfo[key].name}`}
                                            className="has-ratio border1"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </figure>
                                </div>
                            )
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default SegmindPixelflow;