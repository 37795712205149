import React from "react"

const HomeSpotlightSegmind = () => {

    return (
        <div className='is-flex is-flex-direction-column pt-6 m-0'>
            <p className='pb-2 container is-size-3 has-text-weight-bold colorBlack2 has-text-left'>AI spotlight: PixelFlow</p>
            <p className='container is-size-4 has-text-weight-normal colorBlack2 has-text-left'>
            Segmind, the maker of PixelFlow, a no-code AI sequencer, enables creatives and technologists to invent and produce unique generative audio, image, and video content with greater specificity and control. PixelFlow contains the largest collection of AI models on the market that, when combined, create infinite opportunities for product teams to tell engaging stories. <a href="/segmind">View experiment</a>
            </p>
            <div className='container pt-6'>
            <img src='/images/segmind-spotlight.jpg' alt='Screenshot of the PixelFlow AI sequencer.' className='border1 imgOverlay' />
            </div>
        </div>
    );
};

export default HomeSpotlightSegmind;



