import React from 'react';

const HomeStatement = () => {

    return (

        <div className='container py-6'>
          <div className='m-0 px-0 pt-3 pb-6'>
            <p className='h3 has-text-weight-bold has-text-white'>
               Guava Ice Cream is the personal brand of Anthony Hall. is a fiercely human business and technology consulting company that teams with leaders who expect more. So we bring more.
            </p>
          </div>
        </div>
    );
};

export default HomeStatement;