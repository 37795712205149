import React from "react";

const Footer = () => {

    return (
        <div className='m-0 py-4'>
          <p className='microText has-text-centered'>© 2024 Guava Ice Cream, LLC.</p>
        </div>
    );
} ;

export default Footer;