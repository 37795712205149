import React, { useEffect, useState, Suspense } from 'react';
import ClientCard from './ClientCard';
import Modal from './Modal';
import Footer from './Footer';

const NavPublic = React.lazy(() => import('./NavPublic'));

function Clients({ colorView }) {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Filter states
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc', 'desc', 'year'

  // Get unique years and industries for filters
  const years = [...new Set(clients.map(client => client.Year))].sort((a, b) => b - a);
  const industries = [...new Set(clients.map(client => client.Industry))].sort();

  useEffect(() => {
    const fetchClients = async () => {
      const isDevelopment = process.env.NODE_ENV === 'development';
      const apiUrl = isDevelopment
        ? 'http://localhost:5555/api/clients'
        : '/api/clients';

      try {
        setIsLoading(true);
        const response = await fetch(apiUrl);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setClients(data);
        setFilteredClients(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Filter and sort clients
  useEffect(() => {
    let result = [...clients];

    // Apply year filter
    if (selectedYear) {
      result = result.filter(client => client.Year.toString() === selectedYear);
    }

    // Apply industry filter
    if (selectedIndustry) {
      result = result.filter(client => client.Industry === selectedIndustry);
    }

    // Apply sorting
    switch (sortOrder) {
      case 'asc':
        result.sort((a, b) => a.Client.localeCompare(b.Client));
        break;
      case 'desc':
        result.sort((a, b) => b.Client.localeCompare(a.Client));
        break;
      case 'year':
        result.sort((a, b) => b.Year - a.Year);
        break;
      default:
        break;
    }

    setFilteredClients(result);
  }, [clients, selectedYear, selectedIndustry, sortOrder]);

  if (isLoading) {
    return <div className="container p-6">Loading...</div>;
  }

  if (error) {
    return <div className="container p-6 has-text-danger">Error: {error}</div>;
  }

  return (
    <>
    <section className="theme-light section m-0 p-0" data-theme="light">
      <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-space-between">
        <div className="is-flex is-flex-direction-column">
          {/* Navigation */}
          <div className="p-6 m-0">
            <Suspense fallback={<div>Loading...</div>}>
              <NavPublic colorView="light" />
            </Suspense>
          </div>

          {/* Header and Filters */}
          <div className="container">
            <div className="m-0 px-0 pb-0 pt-6 is-flex is-flex-direction-columns is-justify-content-space-between is-align-items-center">
              <div className="m-0 p-0 m-0">
                <p className="is-size-3 has-text-weight-bold colorBlack2">
                  Clients <Modal />
                </p>
              </div>
              <div className="m-0 p-0">
                {/* Filters and Sort */}
                <div className="m-0 p-0">
                  {/* Year Filter */}
                  {/* <div className="select mx-1">
                    <select 
                      className='selectGuava'
                      value={selectedYear} 
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      <option value="">All years</option>
                      {years.map(year => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div> */}

                  {/* Industry Filter */}
                  <div className="select mx-1">
                    <select 
                      className='selectGuava'
                      value={selectedIndustry} 
                      onChange={(e) => setSelectedIndustry(e.target.value)}
                    >
                      <option value="">All sectors</option>
                      {industries.map(industry => (
                        <option key={industry} value={industry}>{industry}</option>
                      ))}
                    </select>
                  </div>

                  {/* Sort Order */}
                  <div className="select mx-1">
                    <select 
                      className='selectGuava'
                      value={sortOrder} 
                      onChange={(e) => setSortOrder(e.target.value)}
                    >
                      <option value="asc">Sort: A-Z</option>
                      <option value="desc">Sort: Z-A</option>
                      <option value="year">Sort: Recent</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Client Cards Grid */}
          <div className="columns is-multiline is-mobile m-0">
            {filteredClients.map((client) => (
              <div key={client._id} className="column is-12-mobile is-6-tablet is-3-desktop p-4">
                <ClientCard client={client} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

    <section className='section m-0 p-0'>
        <Footer />
    </section>
    </>
  );
}

export default Clients;