import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Headlines = () => {
    return (
        <div className='headlines m-0 p-0'>
            <div className='p-0 m-0'>
                <div className='p-0 m-0 has-text-weight-bold has-text-white loading-pulse'>
                    <p className='h1 is-style-sentence-case'>Adopt gen AI</p>
                    <p className='h2 is-style-sentence-case'>Increase business ROI</p>
                </div>
            </div>
            <div className="p-0 mt-5 buttons">
                <Link to="/segmind">
                    <button className="button is-primary">Explore PixelFlow</button>
                </Link>
            </div>
        </div>
    );
};

export default Headlines;