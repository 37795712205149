import React, { useState } from 'react';

const HomeRecentEngagements = ({ engagement }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
        <div className="tall-box" 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}>
            {isHovered ? (
                <>
                <div className={`m-0 p-0 is-flex is-flex-direction-column is-justify-content-space-between ${engagement.Year} border1 cursor`}>
                    <div>
                        <p className="px-6 pt-6 pb-1 m-0 is-size-5 has-text-weight-bold colorBlack2 has-text-left">{engagement.Title}</p>
                        <p className="px-6 m-0 is-size-5 has-text-weight-normal colorBlack2 has-text-left">{engagement.Description}</p>
                    </div>
                    <div>
                        <p className="px-6 pb-6 m-0 is-size-5 has-text-weight-bold colorPink1 has-text-left"><a target="_blank" href={engagement.Link}>{engagement.CTA}</a></p>
                    </div>
                </div>
                </>
            ) : (
                <>
                <div className='m-0 p-0 is-flex is-flex-direction-column backgroundColorPink3 border1 cursor'>
                    <div className="pb-2"><img src={engagement.Industry} alt={engagement.Title} className="imgOverlay" /></div>
                    <p className="px-6 pt-5 pb-1 m-0 is-size-7 has-text-weight-bold colorBlack2 is-uppercase has-text-left">{engagement.Type}</p>
                    <p className="px-6 m-0 is-size-5 has-text-weight-normal colorBlack2 has-text-left">{engagement.Client}</p>
                </div>
                </>
            )}
        </div>  
    </>
    );
};

export default HomeRecentEngagements;
